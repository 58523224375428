import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { media } from '../../../util/breakpoints'
import { sendCreateAccountEvent } from '../../../shared/google-analytics'

function FooterCTABlock() {
  return (
    <Background>
      <Container>
        <Title>Seja mais um dos milhares de usuários satisfeitos que usam o gramlink. Crie sua conta!</Title>
        <CTALink onClick={() => sendCreateAccountEvent('footer cta banner')} to="/criar-conta">Criar conta grátis →</CTALink>
      </Container>
    </Background>
  )
}

const Background = styled.div`
  background-color: #FF4242;
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  max-width: 1200px;
  margin: 0 auto;
  padding: 70px;

  text-align: left;

  ${media.upToTabM} {
    min-height: 100vh;
    padding: 70px 5%;
  }
`

const Title = styled.h2`
  margin: 0;
  width: 100%;
  padding-bottom: 40px;

  color: #FFFFFF;
  font-size: 42px;
  font-weight: 600;
  text-align: center;

  ${media.tabMUp} {
    font-size: 48px;
  }
`

const CTALink = styled(Link)`
  padding: 8px 24px;
  margin: 40px 0px 8px;
  background: #FFFFFF;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15),
              4px 4px 5px rgba(0, 0, 0, 0.15);

  border-radius: 4px;
  
  font-weight: bold;
  font-size: 24px;
  text-decoration: none;
  color: #FF4242;

`


export default FooterCTABlock

import React, { InputHTMLAttributes } from 'react'
import styled from 'styled-components'

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean
}

function Input(props: IInputProps) {
  return (
    <StyledInput {...props}/>
  )
}

const StyledInput = styled.input<IInputProps>`
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  margin-top: 4px;
  padding: 4px 12px;

  border: 1px solid ${props => props.hasError ? '#ff6161' : '#ced4da'};
  border-radius: 4px;

  font-size: 16px;
  font-family: 'Lato', sans-serif;

  outline: none;
`

export default Input

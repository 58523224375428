import React, { useState } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { useStores } from '../../../../stores/hooks'

const COUPON = 'COMPARTILHE50'

function ShareCouponModal() {
  const [copyTextLabel, setCopyTextLabel] = useState('copiar')
  const { modalStore } = useStores()

  const copyText = () => {
    navigator.clipboard.writeText(COUPON)
      .then(_ => changeText())
  }

  const changeText = () => {
    setCopyTextLabel('copiado!')
    setTimeout(() => {
      setCopyTextLabel('copiar')
    }, 2000)
  }

  return (
    <ModalContainer onClick={(e) => e.stopPropagation()}>
      <Title>Obrigado por compartilhar! <span role="img" aria-label="ta-da">🎉</span></Title>
      <Text>Compartilhando o gramlink com seus amigos, você nos ajuda a crescer. E para te ajudar a crescer, estamos te dando um cupom de <b>50% de desconto por três meses</b> de assinatura Premium!</Text>
      <Text>É só copiar o cupom abaixo e o usar na página de pagamento. <span role="img" aria-label="wink">😉</span></Text>
      <CouponWrapper>
        <CouponText>{COUPON} <CopyButton onClick={copyText}>{copyTextLabel}</CopyButton></CouponText>
      </CouponWrapper>
      <ProPageLink to="/painel/pro" onClick={(e) => modalStore.closeAll()}>Conhecer planos</ProPageLink>
    </ModalContainer>
  )

}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  padding: 24px;

  border-radius: 6px;

  background-color: #fff;
  color: #212121;

  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05),
              2px 4px 4px rgba(0, 0, 0, 0.05),
              4px 8px 8px rgba(0, 0, 0, 0.05),
              8px 16px 16px rgba(0, 0, 0, 0.05),
              16px 32px 32px rgba(0, 0, 0, 0.05);
`

const Title = styled.p`
  font-size: 24px;
  font-weight: 600;
`

const Text = styled.p`
  width: 100%;
  margin: 4px;
`

const CouponWrapper = styled.div`
  display: flex;
  justify-items: center;
  margin: 24px 0;
  font-size: 24px;
`

const CouponText = styled.p`
  display: flex;
  align-items: center;
  margin: 0;

  color: #ff4242;
  font-weight: 600;
`

const CopyButton = styled.span`
  margin-left: 12px;
  padding: 6px 6px;

  font-size: 16px;
  font-weight: 400;
  color: #676767;
  line-height: 14px;

  border: 1px solid #676767;
  border-radius: 4px;

  cursor: pointer;
`

const ProPageLink = styled(Link)`
  font-weight: 600;
  color: #3772FF;
`

export default observer(ShareCouponModal)

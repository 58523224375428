import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {observer} from 'mobx-react'
import { useHistory } from 'react-router-dom'

import { useStores } from '../../../stores/hooks'
import Button from '../../common/Button'
import GramInput from '../../common/GramInput'
import { validatePassword } from '../../../validation'
import { useToasts } from 'react-toast-notifications'

function ResetPassword() {
  const { accountStore } = useStores()
  const history = useHistory()
  const {addToast} = useToasts()

  const [password, setPassword] = useState('')
  const [token, setToken] = useState('')

  const [formError, setFormError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (accountStore.isAuthenticated) {
      history.replace('/painel')
    }

    const query = new URLSearchParams(history.location.search);
    const token = query.get('token')

    if (token) {
      setToken(token)
    } else {
      addToast('Token inválido.', {appearance: 'error'})
    }
  }, [accountStore, history, addToast, setToken])

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target

    setFormError(validatePassword(value))
    setPassword(value)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if(!formError) {
      setIsLoading(true)

      accountStore.sendResetPassword(password, token)
        .then(_ => addToast('Senha redefinida.', {appearance: 'success'}))
        .catch(_ => addToast('Alguma coisa deu errado.', {appearance: 'error'}))
        .finally(() => {
          setIsLoading(false)
          history.replace('/entrar')
        })
      }
  }

  return (
    <Container>
      <Title>Digite sua nova senha abaixo</Title>
      <FormContainer>
        <form onSubmit={handleSubmit}>
          <GramInput
            labelText="Senha:"
            error={formError}
            onChange={handleOnChange}
            value={password}
            name="password"
            type="password"
          />
          <Button isLoading={isLoading} type="submit">
            Enviar senha
          </Button>
        </form>
      </FormContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 55px);
  padding-bottom: 40px;
  background-color: #faf9f9;
`

const FormContainer = styled.div`
  box-sizing: border-box;
  width: 90%;
  max-width: 420px;
  margin: 36px auto 0;
  padding: 16px;

  border: 1px solid #dcdcdc;
  border-radius: 4px;

  background-color: #fff;

  box-shadow: 2px 2px 3px 0px #efefef;
`

const Title = styled.h1`
  padding: 48px 10px 12px;
  margin: 0;

  line-height: 36px;
  font-size: 28px;

  text-align: center;
  color: #3c3c3c;
`

export default observer(ResetPassword)

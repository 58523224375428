export const breakpoints = {
  mobXS: 320,
  mobS: 360,
  mobM: 375,
  mobL: 425,
  mobXL: 525,
  tabM: 768,
  deskG: 1024,
  deskGG: 1366,
}

const screen = '@media screen and'

export const media = {
  mobXS: `${screen} (max-width: ${breakpoints.mobS - 1}px)`,
  mobS: `${screen} (min-width: ${
    breakpoints.mobS
  }px) and (max-width: ${breakpoints.mobM - 1}px)`,
  mobSUp: `${screen} (min-width: ${breakpoints.mobS}px)`,
  mobM: `${screen} (min-width: ${
    breakpoints.mobM
  }px) and (max-width: ${breakpoints.tabM - 1}px)`,
  mobMUp: `${screen} (min-width: ${breakpoints.mobM}px)`,
  upToMobL: `${screen} (max-width: ${breakpoints.mobL - 1}px)`,
  mobLUp: `${screen} (min-width: ${breakpoints.mobL}px)`,
  upToMobXL: `${screen} (max-width: ${breakpoints.mobXL - 1}px)`,
  upToTabM: `${screen} (max-width: ${breakpoints.tabM - 1}px)`,
  tabM: `${screen} (min-width: ${
    breakpoints.tabM
  }px) and (max-width: ${breakpoints.deskG - 1}px)`,
  tabMUp: `${screen} (min-width: ${breakpoints.tabM}px)`,
  upToDeskG: `${screen} (max-width: ${breakpoints.deskG - 1}px)`,
  deskG: `${screen} (min-width: ${
    breakpoints.deskG
  }px) and (max-width: ${breakpoints.deskGG - 1}px)`,
  deskGUp: `${screen} (min-width: ${breakpoints.deskG}px)`,
  deskGG: `${screen} (min-width: ${breakpoints.deskGG}px)`,
}

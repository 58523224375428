import React from 'react'
import styled from 'styled-components'


function LoadingIcon() {
  return (
    <SVGIcon height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11"></circle>
    </SVGIcon>
  )
}

const SVGIcon = styled.svg`
  fill: none;
  stroke: rgb(255, 255, 255);
  stroke-dasharray: 69.115px;
  stroke-dashoffset: 55.292px;
  stroke-linecap: round;
  stroke-width: 2px;
  transform-origin: center center;
  animation: 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) 0s infinite normal none running spin;

  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }
`

export default LoadingIcon

import { IEventsTotal, IEventsDetails, IExportableEvent } from '../interfaces'
import { BaseService } from './BaseService'

interface IEventsTotalRequest {
  data: IEventsTotal
}

interface IEventsDetailsRequest {
  data: IEventsDetails
}

interface IExportableEventsRequest {
  data: {
    event_records: IExportableEvent[],
    links_clicks_per_day: {
      count: number
      date: string
      link_name: string
    }[]
  }
}

class EventsService extends BaseService {

  async getTotal(from: Date, to: Date): Promise<IEventsTotalRequest> {
    return this.get(`/account/events/total`, {
      params: {
        from: from.toISOString(),
        to: to.toISOString()
      }
    })
  }

  async getDetails(from: Date, to: Date): Promise<IEventsDetailsRequest> {
    return this.get(`/account/events/details`, {
      params: {
        from: from.toISOString(),
        to: to.toISOString()
      }
    })
  }

  async getExportableEvents(from: Date, to: Date): Promise<IExportableEventsRequest> {
    return this.get(`/account/events/exportable`, {
      params: {
        from: from.toISOString(),
        to: to.toISOString()
      }
    })
  }

}

export default new EventsService()

import { observable, action } from 'mobx'
import { IEventsTotal, IEventsDetails } from '../interfaces'
import EventsService from '../services/EventsService'


class EventStore {
  @observable
  eventsTotal: IEventsTotal = { clicks: 0, pageviews: 0 }

  @observable
  eventsDetails: IEventsDetails = {
    clicks: [],
    pageviews: [],
    online_pageviews: [],
    offline_pageviews: [],
    links_clicks: []
  }

  @action
  async getTotal(from: Date, to: Date) {
    const response = await EventsService.getTotal(from, to)

    this.eventsTotal = response.data
  }

  @action
  async getDetails(from: Date, to: Date) {
    const response = await EventsService.getDetails(from, to)

    this.eventsDetails = response.data
  }
}

const storeInstance = new EventStore()
export default storeInstance

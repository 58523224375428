import { isEmailValid } from '../util/email';

// eslint-disable-next-line no-useless-escape
export const validUsernameRegex = /(^[a-z0-9\.\_]+$)/i;
// eslint-disable-next-line no-useless-escape
export const validUrlRegex = RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/i);

export const validPhoneRegex = RegExp(/([0-9]{10,11})/i);

export const validateUsername = (value: string): string => {
  let error = ''

  if (value.length < 2) {
    error = 'Usuário muito curto'
  } else if (!validUsernameRegex.test(value)) {
    error = 'Usuário inválido'
  }

  return error
}

export const validateName = (value: string): string => {
  return value.length < 2 ? 'Nome muito curto' : ''
}

export const validateEmail = (value: string): string => {
  return !isEmailValid(value) ? 'Email inválido' : ''
}

export const validatePassword = (value: string): string => {
  return value.length < 8 ? 'Senha muito curta' : ''
}

export const validateUrl = (value: string): string => {
  return !validUrlRegex.test(value) ? 'URL inválida' : ''
}

export const validateLinkName = (value: string): string => {
  return !value ? 'Nome deve ter pelo menos uma letra' : ''
}

export const validateMessage = (value: string): string => {
  return !value ? 'Mensagem deve ter pelo menos uma letra' : ''
}

export const validateDescription = (value: string): string => {
  return !value ? 'O texto não pode ser vazio' : ''
}

export const validatePhone = (value: string): string => {
  return !validPhoneRegex.test(value) ? 'Número de telefone inválido' : ''
}

import { ILink, ILinkModel } from '../interfaces'
import { BaseService } from './BaseService'

interface ICreateLinkRequest {
  data: ILinkModel
}

interface IUpdateLinkOrderRequest {
  data: number[]
}

interface IUpdateLinkOrderDTO {
  [id: string]: number
}

class LinkService extends BaseService {

  async createLink(data: ILink): Promise<ICreateLinkRequest> {
    return this.post('/link', data)
  }

  async updateLink(id: number, data: ILink): Promise<ICreateLinkRequest> {
    return this.put(`/link/${id}`, data)
  }

  async deleteLink(id: number): Promise<IUpdateLinkOrderRequest> {
    return this.delete(`/link/${id}`)
  }

  async updateLinkOrder(data: IUpdateLinkOrderDTO): Promise<IUpdateLinkOrderRequest> {
    return this.post(`/link/update-order`, data)
  }
}

export default new LinkService()

import React from 'react'
import accountStore from '../AccountStore'
import modalStore from '../ModalStore'
import linkStore from '../LinkStore'
import eventStore from '../EventStore'

export const storesContext = React.createContext({
  accountStore,
  modalStore,
  linkStore,
  eventStore,
})
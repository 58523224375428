import { IAccount, IBaseAccountInfo, ILoginInfo } from '../interfaces'
import { BaseService } from './BaseService'

interface IAccountRequest {
  data: IAccount
}

interface ILoginRequest {
  data: {
    token: string
  }
}

class AccountService extends BaseService {

  async getAccount(accountSlug: string): Promise<IAccountRequest> {
    return this.get(`/account/${accountSlug}`)
  }

  async createAccount(data: IBaseAccountInfo): Promise<IAccountRequest> {
    return this.post('/account', data)
  }

  async updateAccount(data: Partial<IAccount>): Promise<IAccountRequest> {
    return this.put('/account', data)
  }

  async login(data: ILoginInfo): Promise<ILoginRequest> {
    return this.post<ILoginRequest>('/account/login', data)
  }

  async sendForgotPasswordEmail(email: string): Promise<any> {
    return this.post<any>('/account/reset-password', {email: email})
  }

  async sendResetPassword(password: string, token: string): Promise<any> {
    return this.post<any>(`/account/update-password/${token}`, {
      password,
      password_confirmation: password
    })
  }

  async whoami(): Promise<IAccountRequest> {
    return this.get('/me')
  }

  logout(): void {
    this.setAccessToken('')
  }
}

export default new AccountService()

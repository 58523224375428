import { observable, action, computed } from 'mobx'
import { IAccount, IBaseAccountInfo, IConfig, IContacts, IEventsTotal, ILoginInfo } from '../interfaces'
import AccountService from '../services/AccountService'
import EventsService from '../services/EventsService'
import linkStore from './LinkStore'
import { ITheme } from '../components/Admin/Panel/Appearence/ThemeForm/themes'
import StripeService from '../services/StripeService'

class AccountStore {
  @observable
  account?: IAccount

  @observable
  eventsTotal?: IEventsTotal

  @computed
  get isAuthenticated(): boolean {
    return !!this.account || !!AccountService.getAccessToken()
  }

  @action
  async fetchAccount(account: string) {
    const response = await AccountService.getAccount(account)

    this.account = response.data
  }

  @action
  async whoami() {
    try {
      const response = await AccountService.whoami()

      this.account = response.data
      // this.account = {...response.data, account_type: 'free'}
      linkStore.setLinks(this.account.links)

      if (this.account.account_type === 'premium') {
        await this.loadEventsTotal()
      }
    } catch (error) {
      this.logout()

      throw error
    }
  }

  @action
  async createAccount(data: IBaseAccountInfo) {
    try {
      await AccountService.createAccount(data)

      await this.login({
        email: data.email,
        password: data.password
      })
    } catch (error) {
      throw error.response
    }
  }

  @action
  async login(data: ILoginInfo) {
    const response = await AccountService.login(data)

    AccountService.setAccessToken(response.data.token)
  }

  @action
  async sendForgotPasswordEmail(email: string) {
    return AccountService.sendForgotPasswordEmail(email)
  }

  @action
  async sendResetPassword(password: string, token: string) {
    return AccountService.sendResetPassword(password, token)
  }

  @action
  logout() {
    AccountService.setAccessToken('')
    this.account = undefined
  }

  @action
  async upload(file: string, extension: string, prefix: string = ''): Promise<string> {
    const fileName = `${prefix}${this.account?.username}.${extension}`

    const storageService = await import('../services/FirebaseStorageService')

    await storageService.default.uploadBase64(fileName, file)
    return await storageService.default.getItemUrl(fileName)
  }

  @action
  async uploadImage(file: string, extension: string, prefix: string = '') {
    const imageUrl = await this.upload(file, extension, prefix)

    return this.updateImageUrl(imageUrl)
  }

  @action
  async updateImageUrl(imageUrl: string) {
    const response = await AccountService.updateAccount({
      image_url: imageUrl as string
    })

    this.account!.image_url = imageUrl

    return response
  }

  @action
  async updateNameAndDescription(name: string, description: string) {
    const response = await AccountService.updateAccount({
      name,
      description
    })

    this.account!.name = name
    this.account!.description = description

    return response
  }

  @action
  async updateTheme(theme: ITheme) {
    const newConfig = {
      primary_color: theme.primaryColor,
      secondary_color: theme.secondaryColor,
      gradient_from: theme.gradient ? theme.gradient.from : '',
      gradient_to: theme.gradient ? theme.gradient.to : '',
    }

    const response = await AccountService.updateAccount({
      configs: newConfig
    })

    this.account!.configs = newConfig

    return response
  }

  @action
  async updateContacts(contacts: IContacts) {
    const response = await AccountService.updateAccount({contacts})

    this.account!.contacts = contacts

    return response
  }

  @action
  async updateConfig(config: IConfig) {

    const response = await AccountService.updateAccount({
      configs: {...this.account!.configs, ...config}
    })

    this.account!.configs = {...this.account!.configs, ...config}

    return response
  }

  @action
  async loadEventsTotal() {
    const to = new Date()
    const from = new Date(to.getTime() - 7 * 24 * 60 * 60 * 1000)

    const response = await EventsService.getTotal(from, to)
    this.eventsTotal = response.data
  }

  async fetchStripeUrl(): Promise<string> {
    const response = await StripeService.fetchStripeUrl()
    return response.data.url
  }

  async fetchStripeCheckoutId(): Promise<string> {
    const response = await StripeService.fetchStripeCheckoutSession()
    return response.data.id
  }
}

const storeInstance = new AccountStore()
export default storeInstance

import { BaseService } from './BaseService'

interface IEventsTotalRequest {
  data: {
    url: string
  }
}

interface IStripeCheckoutSession {
  data: {
    id: string
  }
}

class StripeService extends BaseService {

  async fetchStripeUrl(): Promise<IEventsTotalRequest> {
    return this.get(`/stripe`)
  }

  async fetchStripeCheckoutSession(): Promise<IStripeCheckoutSession> {
    return this.get(`/stripe/checkout`)
  }

}

export default new StripeService()

import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'

import { Modal } from '../../../stores/ModalStore'
import { useStores } from '../../../stores/hooks'
import ShareCouponModal from './ShareCouponModal'

function ApplicationModal() {
  const { modalStore } = useStores()

  const ModalWrapper = (props: {children: React.ReactNode}) => {
    return <ModalOverlay onClick={e => modalStore.closeAll()}>
      <ModalContentWrapper>
        <CloseButton>fechar</CloseButton>
        {props.children}
      </ModalContentWrapper>
    </ModalOverlay>
  }

  switch (modalStore.current) {
    case Modal.SHARE_50OFF:
      return <ModalWrapper><ShareCouponModal/></ModalWrapper>
  }


  return <></>
}

const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0,0,0,0.3);
`

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const CloseButton = styled.span`
  align-self: flex-end;
  padding: 8px;

  color: white;
  font-weight: 600;

  cursor: pointer;
`

export default observer(ApplicationModal)

import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { sendCreateAccountEvent } from '../../../shared/google-analytics'
import GramInput from '../../common/GramInput'
import { media } from '../../../util/breakpoints'
import { useStores } from '../../../stores/hooks'
import { validUsernameRegex } from '../../../validation'

let delayTimer: number

function CTABlock() {
  const defaultInputValue = 'gramlink.co/'
  const [username, setUsername] = useState(defaultInputValue)
  const {accountStore} = useStores()
  const [error, setError] = useState('')

  const handleCreateAccountClick = (event: React.MouseEvent) => {
    sendCreateAccountEvent('home cta banner')
  }

  const handleUsernameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target

    if (delayTimer) {
      clearTimeout(delayTimer)
    }

    const trimmedValue = value.replace(' ', '').toLowerCase()
    if (trimmedValue.startsWith(defaultInputValue)) {
      const localUsername = trimmedValue.replace(defaultInputValue, '')
  
      if (validUsernameRegex.test(localUsername)) {
        setUsername(trimmedValue)

        delayTimer = setTimeout(() => {
          accountStore.fetchAccount(localUsername)
            .then(() => setError('Essa URL já está sendo usada'))
            .catch(() => setError(''))
        }, 1000)
      } else if (value === defaultInputValue) {
        setUsername(value)
      }
    }
  }

  return (
    <CTAWrapper>
      <CTAContainer>
        <Title>Todo seu conteúdo em um só lugar!</Title>
        <SubTitle>Divulgue seus trabalhos, produtos e redes com apenas um link</SubTitle>
        <InputWrapper>
          <GramInput
            error={error}
            name="username"
            onChange={handleUsernameInputChange}
            labelText="Comece escolhendo o seu link:"
            value={username}
          />
          <CTAButton onClick={handleCreateAccountClick} to={`/criar-conta?username=${username.replace('gramlink.co/', '')}`}>Criar minha conta</CTAButton>
        </InputWrapper>
      </CTAContainer>
      <ImageContainer>
        <FirstImage alt="Páginas do gramlink.co" src="/images/smartphone-images/16.png"/>
        <SecondImage alt="Páginas do gramlink.co" src="/images/smartphone-images/7.png"/>
      </ImageContainer>
    </CTAWrapper>
  )
}

const CTAWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;

  margin: 0 auto;
  padding: 85px 70px;

  text-align: left;

  ${media.upToTabM} {
    flex-direction: column;
    text-align: center;
    min-height: 65vh;
    padding: 5%;
  }
`

const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${media.upToTabM} {
    margin-top: 35px;
  }
`

const Image = styled.img`
  max-width: 100%;
  max-height: 400px;
  padding: 8px;

  position: relative;
`

const FirstImage = styled(Image)`
  bottom: 20px;
  left: 30px;
  z-index: 1;
`

const SecondImage = styled(Image)`
  top: 20px;
  right: 30px;
`

const Title = styled.h1`
  margin: 0;
  padding-bottom: 22px;
  font-size: 72px;
  line-height: 74px;

  ${media.upToMobXL} {
    padding: 26px 0 12px;
    font-size: 58px;
    line-height: 60px;
  }
`

const SubTitle = styled.h2`
  margin: 0;
  width: 80%;
  color: #676767;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;

  ${media.upToTabM} {
    margin: 26px auto 12px;
  }

  ${media.upToMobXL} {
    font-size: 24px;
    line-height: 26px;
  }
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  text-align: left;

  margin-top: 36px;

  label {
    color: #676767;
  }

  input {
    color: #676767;
    transition: color .15s ease-in;

    &:focus {
      color: #212121;
    }
  }

  ${media.upToDeskG} {
    flex-direction: column;
    width: 100%;
    > div {
      width: 100%;
    }
  }
`

const CTAButton = styled(Link)`
  padding: 6px 38px;
  min-height: 40px;
  margin-left: 8px;
  border-radius: 4px;
  background-color: #ff4242;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  font-size: 22px;
  text-align: center;

  transition: box-shadow .4s ease-in-out;

  &:hover {
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15),
                4px 4px 5px rgba(0, 0, 0, 0.15);
  }

  ${media.mobLUp} {
    margin-top: 12px;
  }

  ${media.upToDeskG} {
    width: 100%;
    margin-left: 0;
  }
`

export default CTABlock

import React from 'react'
import styled from 'styled-components'

import { media } from '../../../util/breakpoints'

function FeaturesBlock() {
  return (
    <Features>
      <Container>
        <Block>
          <BlockImg alt="Páginas do gramlink.co" src="/images/smartphone-images/19.png"/>
        </Block>
        <Block>
          <Title>Vender nunca foi tão fácil!</Title>
          <Text>Seja com um link para o WhatsApp, seu site ou mesmo exibindo seus produtos, o gramlink te ajuda a vender, facilitando o contato entre você e seus clientes.</Text>
        </Block>
      </Container>
      <ContainerInvertedOnMobile>
        <Block>
          <Title>Todo seu conteúdo. Mesmo.</Title>
          <Text>A sua página no gramlink é onde seu público pode encontrar todo seu conteúdo. Seja ele o seu último vídeo, artigo, receita, suas informações de contato ou produto. Tudo em um só lugar.</Text>
        </Block>
        <Block>
          <BlockImg alt="Painel do gramlink.co" src="/images/smartphone-images/7.png"/>
        </Block>
      </ContainerInvertedOnMobile>
    </Features>
  )
}

const Features = styled.div`
  background-color: #fff;
  min-height: 400px;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  min-height: 100vh;
  padding: 24px;
  flex-direction: column;

  ${media.tabMUp} {
    min-height: auto;
    flex-direction: row;
  }

  ${media.deskGUp} {
    padding: 24px 12%;
  }

  &:first-child {
    background-color: #f6f7fb;
  }

  &:last-child {
    padding-bottom: 72px;
  }
`

const ContainerInvertedOnMobile = styled(Container)`
  flex-direction: column-reverse;
`

const Block = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 100%;

  ${media.tabMUp} {
    max-width: 50%;
  }
`

const BlockImg = styled.img`
  max-width: 100%;
  max-height: 400px;
  padding: 8px;
`

const Title = styled.p`
  margin: 0;
  width: 100%;

  color: #ff4342;
  font-size: 48px;
  font-weight: 600;
  text-align: left;
  line-height: 52px;
`

const Text = styled.p`
  color: #3c3c3c;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
`

export default FeaturesBlock

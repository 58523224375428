import { observable, action, computed } from 'mobx'
import { ILinkModel, ILink } from '../interfaces'
import LinkService from '../services/LinkService'


class LinkStore {
  @observable
  links: ILinkModel[] = []

  setLinks(links: ILinkModel[]) {
    this.links = links
  }

  @computed
  get totalLinks(): number {
    return this.links.length
  }

  @computed
  get orderedLinks(): ILinkModel[] {
    return this.links.slice().sort(
      (linkA, linkB) =>
         linkA.order_view - linkB.order_view
    )
  }

  @action
  async createLink(link: ILink) {
    const response = await LinkService.createLink(link)

    this.links.push(response.data)
  }

  @action
  async updateLink(id: number, link: ILink) {
    const response = await LinkService.updateLink(id, link)

    const updatedLinkIndex = this.links.findIndex(link => link.id === id)

    this.links[updatedLinkIndex] = response.data
  }

  @action
  async deleteLink(id: number) {
    await LinkService.deleteLink(id)

    this.links = this.links.filter(link => link.id !== id)
  }

  @action
  async reorder(startIndex: number, endIndex: number) {
    const result = Array.from(this.links)
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)

    this.links = result.map((link, i) => {
      link.order_view = i
      return link
    })

    const positionList = this.links.reduce((prev, next) => {
      prev[next.id.toString()] = next.order_view
      return prev
    }, {} as {[index: string]: number})

    return LinkService.updateLinkOrder(positionList)
  }

  async upload(file: string, extension: string, name: string): Promise<string> {
    const fileName = `${name}.${extension}`

    const storageService = await import('../services/FirebaseStorageService')

    await storageService.default.uploadBase64(fileName, file)
    return await storageService.default.getItemUrl(fileName)
  }
}

const storeInstance = new LinkStore()
export default storeInstance

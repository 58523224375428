import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import LoadingIcon from '../LoadingIcon'

interface ILoadingWrapper extends HTMLAttributes<HTMLDivElement>{
  isLoading: boolean
}

function LoadingWrapper({isLoading, children, ...props}: ILoadingWrapper) {
  return isLoading ? (
    <Wrapper {...props}>
      <LoadingIcon/>
      <Overlay/>
      {children}
    </Wrapper>
  ) : <>{children}</>
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;

  svg {
    stroke: #797979;
    position: absolute;
    left: calc(50% - 12px);
    top: calc(50% - 12px);
  }
`

const Overlay = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0.3;
`
export default LoadingWrapper

export function sendCreateAccountEvent(label?: string) {
  if (typeof gtag != 'undefined') {
    gtag('event', 'click', {
      'event_category': 'click to create account',
      'event_label': label,
    })
  }
}

export function sendAccountCreatedEvent() {
  if (typeof gtag != 'undefined') {
    gtag('event', 'click', {
      'event_category': 'account created'
    })
  }
}

export function sendEvent(category: string, label?: string) {
  if (typeof gtag != 'undefined') {
    gtag('event', 'click', {
      'event_category': category,
      'event_label': label,
    })
  }
}